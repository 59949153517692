import axios from "axios"; 
import { appBackendAppgainUrl, getConfigHeaders } from "../api"; 
import uploadOnS3 from "../helpers/uploadOnS3"; 
 
const defaultState = { 
  teams: [], 
  team: {}, 
  teamLogo: "", 
  error: null, 
  isPending: null, 
  loaded: false, 
  msg: "", 
}; 
 
const url = "Galarey"; 
 
const EDIT_TEAM = "EDIT_TEAM"; 
const CREATE_TEAM = "CREATE_TEAM"; 
const DELETE_TEAM = "DELETE_TEAM"; 
const LOAD_TEAMS_LIST = "LOAD_TEAMS_LIST"; 
const GET_TEAM = "GET_TEAM"; 
const TEAMS_PENDING = "TEAMS_PENDING"; 
const POST_TEAMS_LOGO = "POST_TEAMS_LOGO"; 
const TEAMS_ERROR = "TEAMS_ERROR"; 
 
export const getTeams = () => async (dispatch, getState) => { 
  dispatch({ type: TEAMS_PENDING }); 
  try { 
    const data = await appBackendAppgainUrl( 
      `${url}`, 
      "get", 
      getConfigHeaders() 
    ); 
    dispatch({ 
      type: LOAD_TEAMS_LIST, 
      payload: data.results, 
    }); 
  } catch (error) { 
    dispatch({ 
      type: TEAMS_ERROR, 
      error, 
    }); 
  } 
}; 
 
export const getTeam = id => async (dispatch, getState) => { 
  dispatch({ type: TEAMS_PENDING }); 
  try { 
    const data = await appBackendAppgainUrl( 
      `${url}/${id}`, 
      "get", 
      getConfigHeaders() 
    ); 
    dispatch({ 
      type: GET_TEAM, 
      payload: data, 
    }); 
  } catch (error) { 
    dispatch({ type: TEAMS_ERROR, error }); 
  } 
}; 
 
export const editTeam = (id, newTeamData) => async (dispatch, getState) => { 
  dispatch({ type: TEAMS_PENDING }); 
  try { 
    await appBackendAppgainUrl( 
      `${url}/${id}`, 
      "put", 
      newTeamData, 
      getConfigHeaders() 
    ); 
    dispatch({ 
      type: EDIT_TEAM, 
      payload: { id, newTeamData }, 
    }); 
  } catch (error) { 
    dispatch({ type: TEAMS_ERROR, error }); 
  } 
}; 
 
export const createTeam = newTeamData => async (dispatch, getState) => { 
  dispatch({ type: TEAMS_PENDING }); 
  try { 
    const res = await appBackendAppgainUrl( 
      `${url}`, 
      "post", 
      newTeamData, 
      getConfigHeaders() 
    ); 
    dispatch({ 
      type: CREATE_TEAM, 
      payload: res, 
    }); 
  } catch (error) { 
    dispatch({ type: TEAMS_ERROR, error }); 
  } 
}; 
 
export const deleteTeam = id => async (dispatch, getState) => { 
  dispatch({ type: TEAMS_PENDING }); 
  try { 
    await appBackendAppgainUrl(`${url}/${id}`, "delete", getConfigHeaders()); 
    dispatch({ 
      type: DELETE_TEAM, 
      payload: id, 
    }); 
  } catch (error) { 
    dispatch({ type: TEAMS_ERROR, error }); 
  } 
}; 
 
// Logo Upload 
// export const uploadTeamLogo = (logo, name) => async dispatch => { 
//   console.log(logo,'logo'); 
//   dispatch({ 
//     type: TEAMS_PENDING, 
//   }); 
//   try { 
//     const data = await uploadOnS3(logo, name); 
//     console.log("data: ", data); 
//     dispatch({ 
//       type: POST_TEAMS_LOGO, 
//       payload: data.Location, 
//     }); 
//   } catch (error) { 
//     dispatch({ 
//       type: TEAMS_ERROR, 
//       error, 
//     }); 
//   } 
// }; 
 
export const uploadTeamLogo = (image) => async (dispatch) => { 
  dispatch({ 
    type: TEAMS_PENDING, 
    payload: true, 
  }); 
  try { 
    const { data } = await axios.post( 
      "https://api.appgain.io/6091222301584b000c4a4f53/upload", 
      image, 
      { 
        headers: { 
          appApiKey: 
          "b516845d5cfc29b1c38154f424b96cf41ad064fb71a0a9321f0be60717b39c46", 
        }, 
      } 
    ); 
     
    dispatch({ 
      type: POST_TEAMS_LOGO, 
      payload: `https://${data}`, 
    }); 
  } catch (error) { 
    dispatch({ 
      type: TEAMS_ERROR, 
      payload: error, 
    }); 
 
  } 
}; 
 
export default function reducer(state = defaultState, action) { 
  switch (action.type) { 
    case LOAD_TEAMS_LIST: 
      return { 
        ...state, 
        isPending: false, 
        error: false, 
        teams: action.payload, 
        loaded: true, 
      }; 
    case GET_TEAM: 
      return { 
        ...state, 
        team: action.payload, 
        loaded: true, 
        isPending: false, 
      }; 
    case TEAMS_PENDING: 
      return { 
        ...state, 
        isPending: true, 
        error: false, 
        loaded: false, 
      }; 
 
    case TEAMS_ERROR: 
      return { 
        ...state, 
        isPending: false, 
        error: action.error, 
        teams: [], 
        loaded: true, 
      }; 
 
    case CREATE_TEAM: 
      return { 
        ...state, 
        isPending: false, 
        error: false, 
        teams: [...state.teams, action.payload], 
        loaded: true, 
      }; 
 
    case EDIT_TEAM: 
      return { 
        ...state, 
        isPending: false, 
        error: false, 
        loaded: true, 
        teams: state.teams.map(team => 
          team.objectId == action.payload.id ? action.payload.newTeamData : team 
        ), 
      }; 
    case POST_TEAMS_LOGO: 
      return { 
        ...state, 
        teamLogo: action.payload, 
        isPending: false, 
        error: false, 
        loaded: true, 
      }; 
    case DELETE_TEAM: 
      return { 
        ...state, 
        isPending: false, 
        error: false, 
        teams: state.teams.filter(team => team.objectId != action.payload), 
        loaded: true, 
      }; 
    default: 
      return state; 
  } 
} 
 
 
 
 
 
 
