import { appBackendAppgainUrl, getConfigHeaders } from "../api"; 
 
const defaultUserInfo = { 
  name: "Appgain", 
  image: 
    "https://www.buckinghamandcompany.com.au/wp-content/uploads/2016/03/profile-placeholder.png", 
}; 
 
const AUTH_PENDING = "AUTH_PENDING"; 
const USER_LOGIN = "USER_LOGIN"; 
const LOGIN_ERROR = "LOGIN_ERROR"; 
 
export const checkUser = (username, pass) => async (dispatch, getState) => { 
  dispatch({ type: AUTH_PENDING }); 
  try { 
    // `Auth/?where={"email": "${email}", "username": "${username}", "pass": "${pass}"}` 
    // https://appbackend.appgain.io/6023b58e54639b053b5c50b2/test/classes/Auth/?where=%7B%22email%22%3A+%22fromPostman1%22%2C+%22username%22%3A+%22fromPostman%22%2C+%22pass%22%3A+%22fromPostman%22%7D 
    // https://appbackend.appgain.io/6023b58e54639b053b5c50b2/test/classes/Auth/?where={%22email%22:%20%22testers+2022@appgain.io%22,%20%22username%22:%20%22tester%22,%20%22pass%22:%20%22test1234%22} 
    const data = await appBackendAppgainUrl( 
      `Auth/?where={"username": "${username}", "pass": "${pass}"}`, 
      "get", 
      getConfigHeaders() 
    ); 
     
    if (data.results.length) { 
      dispatch({ 
        type: USER_LOGIN, 
        payload: data.results[0], 
      }); 
      sessionStorage.setItem("user",data.results[0].username) 
    } else { 
      dispatch({ 
        type: LOGIN_ERROR, 
        error: "user not found", 
      }); 
      alert("User not Found") 
 
    } 
 
  } catch (error) { 
    dispatch({ 
      type: LOGIN_ERROR, 
      error, 
    }); 
  } 
}; 
 
export default function reducer( 
  state = { 
    user: defaultUserInfo, 
    isPending: false, 
    error: "", 
    isLoggedIn: false, 
  }, 
  action 
) { 
  switch (action.type) { 
    case AUTH_PENDING: 
      return { 
        ...state, 
        isPending: true, 
      }; 
    case USER_LOGIN: 
      return { 
        ...state, 
        user: action.payload, 
        isLoggedIn: true, 
        isPending: false, 
      }; 
             
 
    case LOGIN_ERROR: 
      return { 
        ...state, 
        error: action.error, 
      }; 
    default: 
      return state; 
  } 
} 
