import React, { Component } from "react";
import { connect } from "react-redux";
import NotificationSystem from "react-notification-system";

class Footer extends Component {
  state = {};

  componentDidUpdate(prevProps) {
    if (this.props.error)
      if (
        this.props.error.response?.data &&
        this.props.error.response.data.message
      ) {
        this.showNotification(this.props.error.response.data.message);
      } else {
        this.showNotification("Something went wrong, try again later.");
      }
  }

  showNotification(msg) {
    if (this.notificationSystem)
      this.notificationSystem.addNotification({
        message: msg,
        level: "error",
        autoDismiss: 5,
        position: "bc",
      });
  }

  render() {
    return (
      <footer className="footer">
        <div className="container-fluid">
          <nav className="pull-left">
            <ul>
              <li>
                <a href="https://www.appgain.io/" target="_blank">
                  Home
                </a>
              </li>
            </ul>
          </nav>
          <p className="copyright pull-right">
            © 2023{" "}
            <a href="https://www.appgain.io/" target="_blank">
              Appgain
            </a>
            , made with love for a better world
          </p>
          <NotificationSystem ref={(ref) => (this.notificationSystem = ref)} />
        </div>
      </footer>
    );
  }
}

export default connect((state) => ({}))(Footer);
