import React, { useState } from "react"; 
import { Link } from "react-router-dom"; 
import "./TeamsDataTable.css"; 
import dateFormatter from "../../helpers/dateFormatter"; 
import ConfirmModal from "../ConfirmModal/ConfirmModal"; 
import IconBtn from "../IconBtn/IconBtn"; 
import _ from "lodash"; 
 
const TeamsDataTable = ({ team, deleteTeam }) => { 
  const [show, setShow] = useState(false); 
  const openConfirmModalHandler = () => { 
    setShow(true); 
  }; 
 
  const deleteTeamHandler = () => { 
    deleteTeam(team.objectId); 
  }; 
   
 
  return ( 
    <React.Fragment> 
      <tr> 
        <td> 
          <img className='team-img' src={team.Logo} alt={`${team.Name}`} /> 
        </td> 
        <td>{team.Name}</td> 
        <td>{team.Title}</td> 
        <td> 
          <Link to={`/editGallery/${team.objectId}`}> 
            <IconBtn 
              title={"Edit"} 
              iconName={"pencil"} 
              btnBehaviourClass='edit' 
            /> 
          </Link> 
        </td> 
        <td> 
          <IconBtn 
            btnBehaviourClass={"delete"} 
            iconName='trash' 
            onClick={openConfirmModalHandler} 
          /> 
        </td> 
      </tr> 
 
      {show && ( 
        <ConfirmModal 
          handleClose={() => setShow(false)} 
          handleShow={() => setShow(true)} 
          message={`Are you sure you want to delete ${team.Name}?`} 
          setShow={setShow} 
          show={show} 
        > 
          <button className='btn btn-danger' onClick={deleteTeamHandler}> 
            Delete 
          </button> 
        </ConfirmModal> 
      )} 
    </React.Fragment> 
  ); 
}; 
 
export default TeamsDataTable; 
