import React, { useEffect, useState } from "react"; 
import "./Testimonials.css"; 
import { connect } from "react-redux"; 
import { 
  getTestimonials, 
  deleteTestimonial 
} from "../../reducers/Testimonials"; 
import TestimonialsDataTable from "../../components/TestimonialsDataTable"; 
import LoadingSpinner from "../../components/LoadingSpinner/LoadingSpinner"; 
import _ from 'lodash'; 
 
const Testimonials = ({ 
  testimonials, 
  getTestimonials, 
  deleteTestimonial, 
  isPending, 
  history, 
}) => { 
 
  const [data, setData] = useState([]); 
   
 
  useEffect(() => { 
    getTestimonials(); 
  }, []); 
 
  useEffect(() => { 
    setData(testimonials) 
  }, [testimonials]); 
 
  const handleAddTestimonial = () => { 
    history.push('addTestimonial'); 
  } 
  
   function reload(){ 
 
    try { 
       
     fetch('https://api.vercel.com/v1/integrations/deploy/prj_RKAtHdXDlvvw2klYj8ck98nuPneJ/OzNmFnG3vy',{ 
        method:'GET', 
       headers: { 
          'Content-Type': 'application/json', 
        }, 
       }) 
       alert('publishing done successfully') 
 
      } catch (error) { 
        console.log("error", error); 
        alert('failed') 
 
    } 
  } 
 
  if (isPending) { 
    return <LoadingSpinner />; 
  } 
  return ( 
    <div className="card"> 
      <div className="header"> 
        <h4 className="title"> 
          Testimonials 
          <button 
            onClick={reload} 
            className="btn btn-primary addProjectBtn me-2" 
          > 
            publish to the website 
          </button> 
          <button 
            onClick={handleAddTestimonial} 
            className="btn btn-primary addProjectBtn" 
          > 
            <i className="fa fa-plus"></i> Add a Testimonial 
          </button> 
        </h4> 
      </div> 
      <div 
        className="content table-responsive table-full-width" 
        style={{ width: "100%" }} 
      > 
        <table className="table table-hover table-striped"> 
          <thead> 
            <tr> 
              <th>Image</th> 
              <th>customer Name In EN</th> 
              <th>customer Name In AR</th> 
 
              <th>review Content In EN</th> 
              <th>review Content In AR</th> 
 
              <th>Edit</th> 
              <th>Delete</th> 
            </tr> 
          </thead> 
          <tbody> 
            {!_.isEmpty(data) && data.map((testimonial) => { 
              return ( 
                <TestimonialsDataTable 
                  key={testimonial.objectId} 
                  testimonial={testimonial} 
                  isPending={isPending} 
                  deleteTestimonial={deleteTestimonial} 
                /> 
              ) 
            })} 
          </tbody> 
        </table> 
      </div> 
    </div> 
  ); 
}; 
 
export default connect( 
  (state) => ({ 
    testimonials: state.Testimonials.testimonials, 
    isPending: state.Testimonials.isPending, 
  }), 
  (dispatch) => ({ 
    getTestimonials: () => dispatch(getTestimonials()), 
    deleteTestimonial: id => dispatch(deleteTestimonial(id)) 
  }) 
)(Testimonials); 
