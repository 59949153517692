import "./AddNotification.css";

import { FormControl, FormGroup, Button, ToggleButton } from "react-bootstrap";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

import React, { useState, useEffect } from "react";

import { EditorState, convertFromHTML, ContentState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

import { connect } from "react-redux";

import LoadingSpinner from "../../components/LoadingSpinner/LoadingSpinner";
import ConfirmModal from "../../components/ConfirmModal/ConfirmModal";
import { stateToHTML } from "draft-js-export-html";
import _ from "lodash";
import {
  createNotification,
  editNotification,
  getNotification,
} from "../../reducers/Notifications";
const AddNotification = ({
  history,
  isPending,
  notification,
  getNotification,
  editNotification,
  createNotification,
}) => {
  const notificationId =
    window.location.hash.includes("editNotification") &&
    window.location.hash.split("/")[window.location.hash.split("/").length - 1];

  const [isEditingMode, setIsEditingMode] = useState(false);
  // const [notificationText, setNotificationText] = useState('');
  // const [notificationTextInAR, setNotificationTextInAR] = useState('');
  const [notificationText, setNotificationText] = useState(
    EditorState.createEmpty(),
  );
  const [notificationTextInAR, setNotificationTextInAR] = useState(
    EditorState.createEmpty(),
  );
  const [isNotificationRequired, setIsNotificationRequired] = useState(false);

  const [edit, setEdit] = useState(false);
  const [error, setError] = useState("");
  useEffect(() => {
    if (notificationId) {
      getNotification(notificationId);
      setIsEditingMode(true);
    } else {
      setIsEditingMode(false);
    }
  }, [notificationId]);

  const cancel = () => {
    history.go(-1);
  };

  const submitNotificationsPersonDataHandler = (e) => {
    e.preventDefault();
    const newNotificationData = {
      notificationText: stateToHTML(notificationText.getCurrentContent()),

      notificationTextInAR: stateToHTML(
        notificationTextInAR.getCurrentContent(),
      ),
    };

    if (isEditingMode) {
      editNotification(notificationId, newNotificationData);
    } else if (!isEditingMode && notificationText) {
      createNotification(newNotificationData);
    } else {
      setError("url is required");
      return;
    }
    // wipeAmbassadorImageData();
    history.push("/notification");
  };

  useEffect(() => {
    if (Boolean(notification) && notificationId) {
      // setNotificationText(notification.notificationText);
      // setNotificationTextInAR(notification.notificationTextAR);
      setNotificationText(
        EditorState.createWithContent(
          ContentState.createFromBlockArray(
            convertFromHTML(
              notification.notificationText
                ? notification.notificationText
                : "<p>No Notification</p>",
            ),
          ),
        ),
      );
      setNotificationTextInAR(notification.notificationTextInAR);
      setNotificationTextInAR(
        EditorState.createWithContent(
          ContentState.createFromBlockArray(
            convertFromHTML(
              notification.notificationTextInAR
                ? notification.notificationTextInAR
                : "<p>No Notification</p>",
            ),
          ),
        ),
      );

      setEdit(true);
    }
  }, [notification]);

  if (isPending) return <LoadingSpinner />;

  return (
    <div>
      <h2 className="title">
        {isEditingMode ? "Edit Notification" : "Add New Notification"}
      </h2>
      <form
        className="container"
        style={{ minHeight: "auto" }}
        onSubmit={submitNotificationsPersonDataHandler}
      >
        <FormGroup>
          {/* <div className="fieldDiv"> 
            <label id="notificationText">Notification Text</label> 
            <FormControl 
              type="text" 
              value={notificationText} 
              onChange={(e) => setNotificationText(e.target.value)} 
              name="notificationText" 
              htmlFor="notificationText" 
              placeholder="Enter Notification Text" 
              required 
            /> 
          </div> */}

          <div className="fieldDiv">
            <label id="notificationText">Notification Text In EN</label>

            <Editor
              editorState={notificationText}
              toolbarClassName="toolbarClassName"
              wrapperClassName="wrapperClassName"
              editorClassName="editorClassName"
              onEditorStateChange={(e) => setNotificationText(e)}
              onFocus={() => setIsNotificationRequired(false)}
              onBlur={() =>
                notificationText.getCurrentContent().getPlainText().trim()
                  .length <= 0 && setIsNotificationRequired(true)
              }
            />

            {isNotificationRequired && (
              <p
                style={{
                  color: "red",
                  textAlign: "center",
                  fontWeight: 700,
                }}
              >
                Notification field is required
              </p>
            )}
          </div>
        </FormGroup>
        <FormGroup>
          {/*           
<div className="fieldDiv"> 
  <label id="notificationTextInAR">Notification Text In Arabic</label> 
  <FormControl 
    type="text" 
    value={notificationTextInAR} 
    onChange={(e) => setNotificationTextInAR(e.target.value)} 
    name="notificationTextInAR" 
    htmlFor="notificationTextInAR" 
    placeholder="Enter Notification Text" 
    required 
  /> 
</div> 
 */}
          <div className="fieldDiv">
            <label id="notificationTextInAR">Notification Text In AR</label>

            <Editor
              editorState={notificationTextInAR}
              toolbarClassName="toolbarClassName"
              wrapperClassName="wrapperClassName"
              editorClassName="editorClassName"
              onEditorStateChange={(e) => setNotificationTextInAR(e)}
              onFocus={() => setIsNotificationRequired(false)}
              onBlur={() =>
                notificationTextInAR.getCurrentContent().getPlainText().trim()
                  .length <= 0 && setIsNotificationRequired(true)
              }
            />

            {isNotificationRequired && (
              <p
                style={{
                  color: "red",
                  textAlign: "center",
                  fontWeight: 700,
                }}
              >
                Notification field is required
              </p>
            )}
          </div>
        </FormGroup>
        <div className="btns">
          <Button className="submitBtn" type="submit" bsStyle="primary">
            {isEditingMode ? "Edit" : "Submit"}
          </Button>
          <Button className="CancelBtn" bsStyle="primary" onClick={cancel}>
            Cancel
          </Button>
        </div>
        <div className="error">
          <p>{error}</p>
        </div>
      </form>
    </div>
  );
};

const mapStateToProps = (state) => ({
  isPending: state.Notifications.isPending,
  notification: state.Notifications.notification,
});

const actions = (dispatch) => ({
  getNotification: (notificationId) =>
    dispatch(getNotification(notificationId)),
  editNotification: (notificationId, newNotificationData) =>
    dispatch(editNotification(notificationId, newNotificationData)),
  createNotification: (newNotificationData) =>
    dispatch(createNotification(newNotificationData)),
});

export default connect(mapStateToProps, actions)(AddNotification);
