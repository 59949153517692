import React, { useEffect, useState } from "react"; 
import { connect } from "react-redux"; 
 
import NotificationDataTable from '../../components/NotificationDataTable'; 
import LoadingSpinner from "../../components/LoadingSpinner/LoadingSpinner"; 
import _ from 'lodash'; 
import './Notification.css'; 
import { deleteNotification, 
   getNotifications }  
   from "../../reducers/Notifications"; 
 
const Notification = ({ 
  notifications, 
  getNotifications, 
  deleteNotification, 
  isPending, 
  history, 
}) => { 
   
 
  const [data, setData] = useState([]); 
   
 
  useEffect(() => { 
    getNotifications(); 
  }, []); 
 
  useEffect(() => { 
    setData(notifications); 
  }, [notifications]) 
 
  const handleAddCase = () => { 
    history.push('addNotification'); 
  } 
  
   function reload(){ 
 
    try { 
       
      fetch('https://api.vercel.com/v1/integrations/deploy/prj_RKAtHdXDlvvw2klYj8ck98nuPneJ/OzNmFnG3vy',{ 
        method:'GET', 
       headers: { 
          'Content-Type': 'application/json', 
        }, 
       }) 
       alert('publishing done successfully') 
 
      } catch (error) { 
        console.log("error", error); 
        alert('failed') 
 
    } 
  } 
 
  if (isPending) { 
    return <LoadingSpinner />; 
  } 
   
  return ( 
    <div className="card"> 
      <div className="header"> 
        <h4 className="title"> 
        Notification 
          <button 
            onClick={reload} 
            className="btn btn-primary addProjectBtn me-2" 
          > 
            publish to the website 
          </button> 
 
          <button 
            onClick={handleAddCase} 
            className="btn btn-primary addProjectBtn" 
          > 
            <i className="fa fa-plus"></i> Add a notification 
          </button> 
        </h4> 
      </div> 
      <div 
        className="content table-responsive table-full-width" 
        style={{ width: "100%" }} 
      > 
        <table className="table table-hover table-striped"> 
          <thead> 
            <tr> 
             
              <th>notification text In En</th> 
              <th>notification text In AR</th> 
 
              <th>Edit</th> 
              <th>Delete</th> 
            </tr> 
          </thead> 
          <tbody> 
            {!_.isEmpty(data) && data.map((notifications) => ( 
               
              <NotificationDataTable 
                key={notifications.objectId} 
                notification={notifications} 
                isPending={isPending} 
                deleteNotification={deleteNotification} 
              /> 
            ))} 
          </tbody> 
        </table> 
      </div> 
    </div> 
  ); 
}; 
 
export default connect( 
  (state) => 
   ({ 
    notifications: state.Notifications.notifications, 
    isPending: state.Notifications.isPending, 
  }), 
  (dispatch) => ({ 
    getNotifications: () => dispatch(getNotifications()), 
    deleteNotification: (id) => dispatch(deleteNotification(id)), 
  }) 
)(Notification); 
